import React, { useState, useEffect, useReducer } from 'react';
import { getUserHistroyService } from '../../services/campersServices';
import { ChargeAmountReducer, initialChargeAmountModalState, resHandle } from '../../helper';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isOnlySpaces, trimSpace } from '../bookings/bookingHelper';
import ChargeCamperModal from '../reuse/ChargeCamper/ChargeCamperModal';





const UserHistory = props => {

    const [list, setList] = useState([]);

    const [modalState, dispatch] = useReducer(ChargeAmountReducer, initialChargeAmountModalState);
    const [chargeModal, setChargeModal] = useState(false)

    const handleChargeAmountReason = (newReason) => {
        if (isOnlySpaces(newReason)) {
            handleReasonError('Please Enter a valid reason.')
        } else {
            const _reason = trimSpace(newReason)
            dispatch({ type: 'SET_REASON', payload: _reason });
            handleReasonError('')
        }
    };
    const handleReasonError = (error) => {
        dispatch({ type: 'SET_REASON_ERROR', payload: error });
    };
    const handleChargeAmountChange = (amount) => {
        dispatch({ type: 'SET_AMOUNT', payload: amount });
    }
    const handleCamperList = (list) => {
        dispatch({ type: 'SET_CAMPERLIST', payload: list });
    }
    const handleCamperChange = (camper) => {
        dispatch({ type: 'SET_CAMPER_CHANGE', payload: camper });
    }
    const handleMetaDataChange = (b_id, b_no, p_id) => {
        dispatch({ type: 'SET_META_DATA', payload: { b_id, b_no, p_id } });
    }

    const getUserHistroy = () => {
        let query = `?userId=${props.match.params.id}&campCode=${props.userProfile.campCode}`;

        getUserHistroyService(query).then(res => {
            let { status, data } = resHandle(res);
            if (status) {
                setList(data.result);
            }
        })
    }


    useEffect(() => {
        if (props?.match?.params?.id) {
            getUserHistroy();
        }

    }, [props])

    const handleChargeCustomer = (campers, bookingNo, bookingId, programId) => {
        handleMetaDataChange(bookingId, bookingNo, programId)
        setChargeModal(true)
        const _camperList = campers.map(camper => ({ label: camper.camperName, value: camper.camperId }))
        handleCamperList(_camperList)
    }

    const handleCloseChargeModal = (type=1) => {
        dispatch({ type: 'RESET', payload: initialChargeAmountModalState })
        setChargeModal(false)
        if(type===1){
            getUserHistroy()
        }
    }



    return (
        <div className="mt-3 ml-3 mr-3">
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h4>History</h4>
            </div>

            <ul className='user_timeline'>
                {
                    list.map(item => (
                        <li key={item._id}>
                            <time>{moment(item.created).format('DD MMM, YYYY')}</time>
                            <span className='histroy_type'>{item.productPrice ? 'Products' : 'Programs'}</span>


                            {
                                item.productPrice ? (
                                    <div className='timeline_inner'>
                                        <h3>Camp Name: {item?.campName}</h3>
                                        <div className='time_line_programs'>
                                            <h5 className='mr-3'>Products: </h5>
                                            {item.itemName.map((p, i) => <h5 className='m_box' key={i}> {p}</h5>)}
                                        </div>

                                        <h2>Amount: {item.productPrice ? `$${item.productPrice.toFixed(2)}` : 'Free'}</h2>
                                    </div>
                                ) : (
                                    <div className={`timeline_inner ${item?.bookingStatus === 2 ? 'is_cancelled' : 'not_caneclled'}`}>
                                        {item?.bookingStatus === 2 ? <h6 className='has_canceled_program_text'>Canceled</h6> : ''}
                                        <h3>Camp Name: {item.campName}</h3>
                                        <div className=''>
                                            <h5 className='mr-3'>Programs: </h5>
                                            {item?.programs?.length ? item.programs.map((p, i) => <div>
                                                <h5 className='pl-5' key={i}>{p?.programName}</h5>
                                                <div className='d-flex justify-content-end bg-red'>
                                                    <button className='btn btn-primary' onClick={() => handleChargeCustomer(p.campers, item.bookingNumber, item.bookingId, p.programId)}>Charge camper</button>
                                                </div>
                                                <div className='mb-5 pl-5 ml-5'>
                                                    <small className='mr-3'>participants: </small>
                                                    {p.campers.map((cp, ci) => <Link
                                                        to={`/bookings/${item.bookingId}/${cp.camperId}/${p.programId}`}>
                                                        <small
                                                            className={`${((item?.bookingStatus === 2) ? "m_box" : ((cp.status === 2 || cp.status === 4) && cp.isCancelled) ? 'm_box_cancelled' : 'm_box')}`}
                                                            key={ci}>
                                                            {cp.camperName}
                                                        </small>
                                                    </Link>)}
                                                </div>
                                            </div>) : ''}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <small className='m_box'>Booking Number: {item?.bookingNumber}</small>
                                            <h2>Amount: {item.amount ? `$${item.amount.toFixed(2)}` : 'Free'}</h2>
                                        </div>
                                    </div>

                                )
                            }
                        </li>
                    ))
                }
            </ul>
            <ChargeCamperModal
                modalState={modalState}
                chargeModal={chargeModal}
                handleCloseChargeModal={handleCloseChargeModal}
                handleCamperChange={handleCamperChange}
                handleChargeAmountReason={handleChargeAmountReason}
                handleChargeAmountChange={handleChargeAmountChange}
                userId={props.match.params.id}
            />

        </div>
    )
}


const mapStateToProps = state => {
    let userProfile = state.user.userProfile;
    return {
        userProfile
    };
}


export default connect(mapStateToProps)(UserHistory);