import React from 'react';
import { useHistory } from "react-router-dom";
import { Menu } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Bookings from '../booking/Bookings';
import ProfileEdit from './ProfileEdit';
import MyRewards from './MyRewards';
import MyCoupons from './MyCoupons';
import ChangePassword from './ChangePassword';
import EditCamper from './campers/editCamper';
import Gallery from './Gallery';
import Inbox from '../inbox/Inbox';
import Notification from './notification';
import CampDirectorProfileEdit from './campDirectorProfileEdit';
import CampPersonnelProfileEdit from './campPersonnelProfileEdit';
import { connect } from 'react-redux';
import ActivityViewList from './ActivityViewList';
import Participants from './Participants';
import OnBoarding from './OnBoarding';
import Orders from '../booking/Orders';
import PaymentHistory from '../booking/PaymentHistory';
import { DollerIcon } from '../reuse/SVG';


const { SubMenu } = Menu;



const Profile = props => {
    const history = useHistory();
    let url = window.location.href;
    const activeMenu = url.slice(url.lastIndexOf('/') + 1, url.length + 1);  
    
    
    const handleMenuChange = e => {
        if(e.key != 'logout'){
            history.push(`/profile/${e.key}`)
        }else{
            localStorage.clear();
            history.push(`/`);
            window.location.reload();

        }
    }


    return(
        <div className="body_container bg_light">



            <div className="container mt-5 mb-5">
                <div className="row">

                    <div className="col-lg-3 cm_scrondry_menu">
                        
                            {   
                            props.userProfile.UserType == 2 ?
                               <Menu
                                mode="inline"
                                onClick={handleMenuChange}
                                defaultSelectedKeys={[activeMenu]}
                                defaultOpenKeys={[
                                    activeMenu == 'information' ? 'subProfile' : '',
                                    (activeMenu == 'rewards' || activeMenu == 'coupons' || activeMenu == 'reviews-and-ratings' || activeMenu == 'notification' || activeMenu == 'email-preferences') ? 'subMycollection' : '']}>
                                <SubMenu key="subProfile" title={<span><i className="icon-user" /> Profile</span>}>
                                    <Menu.Item key="information">Profile Information</Menu.Item>
                                    <Menu.Item key="change-password">Change Password</Menu.Item>
                                    <Menu.Item key="on-boarding">Org Details</Menu.Item>
                                </SubMenu>
                                <Menu.Item key="logout"><i className="icon-logout" /> Logout</Menu.Item>
                            </Menu> 
                        :
                        props.userProfile.UserType == 4 ?
                       
                        <Menu
                        mode="inline"
                        onClick={handleMenuChange}
                        defaultSelectedKeys={[activeMenu]}
                        defaultOpenKeys={[
                            activeMenu == 'information' ? 'subProfile' : '',
                            (activeMenu == 'rewards' || activeMenu == 'coupons' || activeMenu == 'reviews-and-ratings' || activeMenu == 'notification' || activeMenu == 'email-preferences') ? 'subMycollection' : '']}>
                        <SubMenu key="subProfile" title={<span><i className="icon-user" /> Profile</span>}>
                            <Menu.Item key="information">Profile Information</Menu.Item>
                            <Menu.Item key="change-password">Change Password</Menu.Item>
                            <Menu.Item key="activity-view-list">Activity View List</Menu.Item>
                        </SubMenu>
                        <Menu.Item key="logout"><i className="icon-logout" /> Logout</Menu.Item>

                    </Menu>                         
                    :    
                        <Menu
                            mode="inline"
                            onClick={handleMenuChange}
                            defaultSelectedKeys={[activeMenu]}
                            defaultOpenKeys={[
                                activeMenu == 'information' ? 'subProfile' : '',
                                (activeMenu == 'rewards' || activeMenu == 'coupons' || activeMenu == 'reviews-and-ratings' || activeMenu == 'notification' || activeMenu == 'email-preferences') ? 'subMycollection' : '']}>
                            <SubMenu key="subProfile" title={<span><i className="icon-user" /> Profile</span>}>
                                <Menu.Item key="information">Profile Information</Menu.Item>
                                <Menu.Item key="participant">Participant(s)</Menu.Item>
                                <Menu.Item key="change-password">Change Password</Menu.Item>
                            </SubMenu>
                            <Menu.Item key="bookings"><i className="icon-booking" /> Bookings</Menu.Item>
                            <Menu.Item key="orders"><i className="icon-cart" /> Products</Menu.Item>
                            <Menu.Item key="payment-history" className='_doller_icon'><DollerIcon /> Payments </Menu.Item>
                            <Menu.Item key="online-store"><a href="/online-store" className="icon__cart"><i className="icon-cart" /> Online Camp Store</a></Menu.Item>
                            <SubMenu key="subMycollection" title={<span><i className="icon-folder" />My Collection</span>}>
                                <Menu.Item key="rewards">My Rewards</Menu.Item>
                                <Menu.Item key="coupons">My Coupons</Menu.Item>
                                <Menu.Item key="reviews-and-ratings">My Reviews & Ratings</Menu.Item>
                                <Menu.Item key="notification">Notifications</Menu.Item>
                                <Menu.Item key="email-preferences">Email Preferences</Menu.Item>
                            </SubMenu>
                            <Menu.Item key="inbox"><i className="icon-send" /> Inbox </Menu.Item>
                            <Menu.Item key="logout"><i className="icon-logout" /> Logout</Menu.Item>
                        </Menu>
                    }    
                    </div>
                    <div className="col-lg-9">
                        <Switch>
                            <Route exact path='/profile/bookings' component={ Bookings } />
                            <Route exact path='/profile/bookings/:id' component={ Gallery } />
                            <Route exact path='/profile/information' component={ props.userProfile.UserType == 2 ? CampDirectorProfileEdit  :  props.userProfile.UserType == 4 ? CampPersonnelProfileEdit : ProfileEdit } />
                            <Route exact path='/profile/participant' component={ Participants } />
                            <Route exact path='/profile/rewards' component={ MyRewards } />
                            <Route exact path='/profile/coupons' component={ MyCoupons } />
                            <Route exact path='/profile/change-password' component={ ChangePassword } />
                            <Route exact path='/profile/camper/:id' component={ EditCamper } />
                            <Route exact path='/profile/inbox' component={ Inbox } />
                            <Route exact path='/profile/notification' component={ Notification } />
                            <Route exact path='/profile/activity-view-list' component={ ActivityViewList } />
                            <Route exact path='/profile/on-boarding' component={ OnBoarding } />
                            <Route exact path='/profile/orders' component={ Orders } />
                            <Route exact path='/profile/payment-history' component={ PaymentHistory } />
                        </Switch>
                    </div>
                </div>
            </div>


        </div>
    )
}


const mapStateToProps = state => {
    let { userProfile, loggedIn } = state.user
    return {
        userProfile,
        loggedIn
    };
}

export default connect(mapStateToProps, null)(Profile);