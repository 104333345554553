import React from "react";
import InsuranceComponent from "..";
import RegistarationComponent from "./RegistarationComponent";

const RegistrationCancellation = ({ updateInsuranceQuotes, allPrograms, camperList, insuranceState, userEmail, userName, userLastName,billingDetail}) => {
    return (
        <InsuranceComponent
            client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
            offerStateChange={updateInsuranceQuotes.bind(this)}
            product="registration-cancellation"
            style={{ width: '100%' }}
        >
            {allPrograms.map((items, _ , all) => {
                const eventDates = items.additionalDates?.filter(el => items.selectedDates.find((id) => el._id === id))?.flatMap(d => [d.startDate, d.endDate])?.sort(function (a, b) { return a - b; })
                let startDate;
                let endDate;
                const cartItem = items;
                const campers = cartItem.campers;
                const noOfCampers = campers?.length;
                if (eventDates && eventDates.length > 0) {
                    startDate = new Date(parseInt(eventDates[0])).toISOString().substring(0, 10);
                    endDate = new Date(parseInt(eventDates[eventDates.length - 1])).toISOString().substring(0, 10);
                } else {
                    startDate = new Date(items.startDate).toISOString().substring(0, 10);
                    endDate = new Date(items.endDate).toISOString().substring(0, 10);
                }
                let _multiProgramDiscount = items?.f_multiProgramDiscount ? items?.f_multiProgramDiscount : 0
                let _discountPrice = items?.f_discountPrice ? Math.round((items?.f_discountPrice/noOfCampers*100)/100) : 0
                return (
                    campers.map(camper => {
                        let _couponMoneyValue = camper?.applicableCouponAmount ? camper.applicableCouponAmount : 0
                        const camperDetails = camperList.find(c => c._id === camper.camperId);
                        const _zipCode = billingDetail?.zipCode ? billingDetail?.zipCode : camper?.zipCode
                        const additionalExperienceTotal = camper?.additionalExperience?.length > 0 ?camper?.additionalExperience.reduce((sum, i)  => i.price * (i.numberOfBooking), 0) :0;
                        return (
                            <RegistarationComponent
                                couponMoney={_couponMoneyValue + _multiProgramDiscount + _discountPrice}
                                additionalExperienceTotal={additionalExperienceTotal}
                                key={`out-gap-medical-insurance-offer-${cartItem._id}-${camper._id}`}
                                item={cartItem}
                                insuranceState={insuranceState}
                                userEmail={userEmail}
                                userName={userName}
                                zipCode={_zipCode}
                                userLastName={userLastName}
                                camperDetails={camperDetails}
                                startDate={startDate}
                                endDate={endDate}
                                camperId={camper?.camperId}
                                noOfCampers={noOfCampers}
                            />)
                    })
                );
            })}
        </InsuranceComponent>
    )
}

export default RegistrationCancellation
